<template>
  <div class="home">
    <img src="@/assets/logo.jpg" alt="Andao Alliance Logo" class="logo" />
    <div class="description">
      <h1>安道盟（Andao Alliance）</h1>
      <p>
        使用智能科技武装的新式生产力面向普通技能劳动者提供安居乐业之道的科技型企业，
        致力于以联盟形式发展闭环生态，让生态内企业研发、测试、运维有低成本人力支撑；
        让硬件产品拥有长效收益方案；让普通技能劳动者有更多的就业机会；
        让生产力使用者有灵活度高、成本低的新式生产力试用场景。
      </p>
      <h1 class="construction-notice">
        网站建设中，垂询请拨打400-880-4968
      </h1>
    </div>
    <footer class="footer">
      <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2024116701号</a>
    </footer>
  </div>
</template>

<script>
export default {
  name: "AndaoPortal",
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  padding: 0 20px;
}

.logo {
  width: 200px;
  margin-bottom: 20px;
}

.description {
  max-width: 600px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

p {
  font-size: 16px;
  line-height: 1.6;
}

.construction-notice {
  font-size: 24px;
  color: #F9BE02; /* 设置黄色字体 */
  margin-top: 30px;
}

.footer {
  position: absolute;
  bottom: 20px;
  font-size: 14px;
  color: #888;
}

.footer a {
  color: inherit;
  text-decoration: none;
}
</style>